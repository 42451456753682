import * as React from "react"
import gatsbyConfig from "../../gatsby-config"

import OgImg from 'images/image--top1.webp'
import { Helmet } from "react-helmet"
import { WAVY_LINE_COLOR } from 'consts'
import SectionHeader from 'components/molecules/section-header'

// sections
import { PcHeader, SpHeader } from 'components/organisms/header'
import Company from 'components/organisms/company'
import Project from 'components/organisms/project'
import Footer from 'components/organisms/footer'

// parts
import { BackButton } from 'components/atoms/back-button'

import 'styles/index.scss'

const Privacy = () => {

  const section = 'privacy'
  const title = 'WaaF | プライバシー'
  
  const items = [
    {
      title: "事業者の氏名または名称",
      paragraph: "株式会社ライトハウス"
    },
    {
      title: "個人情報保護管理者",
      paragraph: "代表取締役 CEO(連絡先は、下記〔個人情報苦情及び相談窓口〕)"
    },
    {
      title: "個人情報の利用目的",
      paragraph: "ご入力いただき当社が取得した個人情報は、お問合せ対応のために利用いたします。"
    },
    {
      title: "個人情報の第三者提供について",
      paragraph: "本人の同意がある場合または法令に基づく場合を除き、取得した個人情報を第三者に提供することはありません。"
    },
    {
      title: "個人情報の取扱いの委託について",
      paragraph: "取得した個人情報の取扱いの全部または、一部を委託する場合があります。委託先については、個人情報取扱いに関する契約を締結するなど、適切な管理を行います。"
    },
    {
      title: "保有個人データの開示等の請求等について",
      paragraph: "当社では、保有個人データに関する利用目的の通知、開示、内容の訂正・追加または削除、利用の停止・消去および第三者への提供の停止の請求(「開示等の請求等」といいます。)を受け付けております。その手続きについては、個人情報苦情及びご相談窓口へご連絡下さい。ただし、法令等の規定により開示等できない場合がございます。あらかじめご了承ください。"
    },
    {
      title: "個人情報を入力するにあたっての注意事項",
      paragraph: "必須項目にご入力いただけない場合には、問合せを受付けることができません。"
    },
    {
      title: "本人が容易に認識できない方法による個人情報の取得",
      paragraph: "クッキーやウェブビーコン等を用いるなどして、本人が容易に認識できない方法による個人情報の取得は行っておりません。"
    },
    {
      title: "個人情報の安全管理措置について",
      paragraph: "取得した個人情報については、漏洩、減失またはき損の防止と是正、その他個人情報の安全管理のために必要かつ適切な措置を講じます。このサイトは、SSL(Secure Socket Layer)による暗号化措置を講じています。"
    }
  ]

  const ListItem = (props) => {
    return (
      <li className={`${section}__item-box`}>
        <h5 className={`${section}__item-title`}>{ props.title }</h5>
        <p className={`${section}__item-paragraph`}>{ props.paragraph }</p>
      </li>
    )
  }

  return (
    <main className="theme">
      <Helmet>
        <html lang={ gatsbyConfig.siteMetadata.lang } />
        <title>{ title }</title>
        <meta name="description" content={ gatsbyConfig.siteMetadata.description } />
        <meta name="keywords" content={ gatsbyConfig.siteMetadata.keywords } />
        <meta name="image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:title" content={ title } />
        <meta property="og:site_name" content={ title } />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={ gatsbyConfig.siteMetadata.siteUrl } />
        <meta property="og:image" content={`${gatsbyConfig.siteMetadata.siteUrl}${OgImg}`} />
        <meta property="og:description" content={ gatsbyConfig.siteMetadata.description } />
        <meta property="og:locale" content="ja_JP" />
        <link rel="shortcut icon" href="../images/icon.png" type="image/x-icon" />
        <link rel="apple-touch-icon-precomposed" href="../images/icon.png" />
      </Helmet>
      <PcHeader />
      <SpHeader />
      <section className={section}>
        <SectionHeader
          text='個人情報の取扱について'
          lineColor={ WAVY_LINE_COLOR.NAVY }
        />
        <ul className={`${section}__body`}>
          {
            items.map( i => { return(
              <ListItem
                key={i}
                title={i.title}
                paragraph={i.paragraph}
              />
            )})
          }
          <li className={`${section}__item-box`}>
            <h5 className={`${section}__item-title`}>個人情報苦情及び相談窓口</h5>
            <p className={`${section}__item-paragraph`}>
              株式会社ライトハウス<br />
              個人情報保護管理者 代表取締役 CEO<br />
              連絡先 〒810-0001 福岡県福岡市中央区天神 1-15-5 Mail info@isana-g.com
            </p>
          </li>
        </ul>
        <div className={`${section}__button-box`}>
          <BackButton />
        </div>
      </section>
      <Company />
      <Project />
      <Footer />
    </main>
  )
}

export default Privacy
