import * as React from "react"
import { BACK_BUTTON_TEXT } from 'settings'

export const BackButton = () => {
  return (
    <button
      className="back-button"
      onClick={ () => window.location.href = '/' }>
      { BACK_BUTTON_TEXT }
    </button>
  )
}